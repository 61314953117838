import React, { useState } from "react";
import "./_JawlaCard.scss";
import { ReactComponent as LocIcon } from "../../../../assets/icons/ListSale/Loc.svg";
import { ReactComponent as PhoneIcon } from "../../../../assets/images/ManagementSaleOrg/phone.svg";
import { ReactComponent as WhatsIcon } from "../../../../assets/images/ManagementSaleOrg/whats.svg";
import { ReactComponent as FavIcon } from "../../../../assets/icons/ListSale/Fav.svg";
import { ReactComponent as FavFillIcon } from "../../../../assets/icons/ListSale/FavFill.svg";
import { AddRemoveFavorite } from "../../../../data/slices/favoriteSlice";
import handleName from "../../../../helpers/handleName";
import cookies from "js-cookie";
import { openLinkInNewWindow } from "../../../../helpers/openMediaLink";
import { Modal, Tooltip, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import RemoveFavoriteModal from "../RemoveFavoriteModal/RemoveFavoriteModal";
import { useTranslation } from "react-i18next";
import PdfSection from "../../../PropertyDetails/components/PdfSection/PdfSection";
import MsgCallButtons from "../../../../components/MsgCallButtons/MsgCallButtons";

function JawlaCard({ propertieItem, favorite }) {
  const currentLanguageCode = cookies.get("i18next") || "en";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isRemoveFavoritOpen, setIsRemoveFavoritOpen] = useState(false);
  const { t } = useTranslation();

  const { isAuthenticated } = useSelector((state) => state.auth);
  const handleFavorite = (action) => {
    setIsRemoveFavoritOpen(true);
  };
  const handleDeleteFavoriteFunction = (action) => {
    let msg = "Removed successfully from favorites";

    if (!isAuthenticated) {
      message.info("Please login first");
      navigate("/auth/login");
      return;
    }
    dispatch(
      AddRemoveFavorite({
        body: {
          action: "remove",
          pid: propertieItem?.id,
          offer_type: propertieItem?.offer_type,
        },
      })
    )
      .unwrap()
      .then((res) => {
        if (res.error === 0) {
          message.success(msg);
          setIsRemoveFavoritOpen(false);
        } else {
          message.error(res?.message);
        }
      });
  };
  const handleAddFavoriteFunction = (action) => {
    let msg = "Added successfully to favorites";
    if (!isAuthenticated) {
      message.info("Please login first");
      navigate("/auth/login");
      return;
    }
    dispatch(
      AddRemoveFavorite({
        body: {
          action: "add",
          pid: propertieItem?.id,
          offer_type: propertieItem?.offer_type,
        },
      })
    )
      .unwrap()
      .then((res) => {
        if (res.error === 0) {
          message.success(msg);
          navigate("/favorites");
        } else {
          message.error(res?.message);
        }
      });
  };
  const handleNavigate = () => {
    navigate(`/locations/${propertieItem?.id}`, {
      state: { type: propertieItem?.type },
    });
  };
  return (
    <div className="card-jawla">
      <div className="media">
        <img
          className="image-main"
          src={propertieItem?.main_image}
          alt=""
          onClick={() => openLinkInNewWindow(propertieItem?.ref_id)}
        />
        {/* <div className="fav">
          {!favorite ? (
            <FavIcon onClick={() => handleAddFavoriteFunction()} />
          ) : (
            <FavFillIcon
              onClick={() => {
                handleFavorite();
                // dispatch(updateRemoveFromFavorite(propertieItem));
              }}
            />
          )}
        </div> */}
      </div>
      <div className="content-jawla">
        <p className="title-jawla" onClick={handleNavigate}>
          {propertieItem?.name}
        </p>
        <span className="location">
          <LocIcon />
          <p>
            {handleName(
              propertieItem?.address_ar,
              propertieItem?.address_en,
              currentLanguageCode
            )}
          </p>
        </span>
        <span className="description-jawla">{propertieItem?.details}</span>
        <div className="pdf_section">
          {propertieItem?.pdf_specification?.map((el, index) => (
            <>
              {index < 4 && <PdfSection index={index} key={index} pdf={el} />}
            </>
          ))}
          {propertieItem?.pdf_specification.length >= 4 && (
            <span>+ {propertieItem?.pdf_specification.length - 4}</span>
          )}
        </div>
        <div className="action">
          <span className="more-details" onClick={handleNavigate}>
            {t("more-details")}
          </span>
          <div className="contatt">
            {propertieItem?.user_tour_ref_id && (
              <Tooltip
                color="white"
                title={
                  <p className="tooltip-color">{t("properties.propertyNo")}</p>
                }
              >
                <p className="ref-id">{propertieItem?.user_tour_ref_id}</p>
              </Tooltip>
            )}
            <div className="contact-btn">
              <MsgCallButtons
                type="whatsapp"
                whtsappNumber={propertieItem.whatsapp}
                id={propertieItem.id}
                userPropertyRefID={propertieItem.user_tour_ref_id}
                name={propertieItem.name}
                offerType={propertieItem.offer_type}
              />
            </div>
            <div className="contact-btn">
              <MsgCallButtons
                type="call"
                id={propertieItem.id}
                phoneNumber={propertieItem.whatsapp}
                offerType={propertieItem.offer_type}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        closeIcon={false}
        open={isRemoveFavoritOpen}
        onCancel={() => setIsRemoveFavoritOpen(false)}
        centered
        footer={null}
      >
        <RemoveFavoriteModal
          setIsRemoveFavoritOpen={setIsRemoveFavoritOpen}
          handleFavoriteFunction={handleDeleteFavoriteFunction}
        />
      </Modal>
    </div>
  );
}

export default JawlaCard;
